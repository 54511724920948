function moneyFormat (num) {
    return String(num).replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
}

/**
 * 格式化金额，输出千分位
 * @param {*} num 
 * @returns 
 */
export const formatMoney = (num) => {
  var res;
  var dot = String(num).includes('.');
  if (!dot) {           // 整数
    res = moneyFormat(num) + '.00';
  } else {              // 带小数
    // 四舍五入，然后固定保留2位小数
    const numStr = String((Math.round(num * 100) / 100).toFixed(2));
    dot = numStr.indexOf('.');
    // 截取小数位
    const decimal = numStr.substr(dot + 1, 2);
    res = moneyFormat(numStr.substr(0, dot)) + '.' + decimal;
  }
  return res;
}