<template>
    <div class="uipay-page">
        <!-- 订单信息 -->
        <div class="uipay-a1">
            <p style="font-size: 0.5rem">
                <span>{{ upayForm.mchName }}</span>
            </p>
            <!--<p>-->
            <!--    <span>订单编号</span>-->
            <!--    <span>{{ upayForm.mchOrderNo }}</span>-->
            <!--</p>-->
        </div>
        <!-- 支付金额 -->
        <div class="uipay-a2">
            <p class="a2-title">支付金额</p>
            <div class="a2-money">
                <span>￥</span>
                <span>
                    <van-field v-model="upayForm.amount" @blur="amountBlur()" type="number" maxlength="12" ref="amount" class="a2-input"/>
                </span>
            </div>
            <van-divider/>
            <!--<div class="a2-to">-->
            <!--    <span>分账方式</span>-->
            <!--    <span @click="() => { showPopup = true; divisionMode = upayForm.divisionMode;}">-->
            <!--      {{ divisionModeText }}-->
            <!--      <van-icon name="arrow" style="margin-left: 2px"/>-->
            <!--    </span>-->
            <!--</div>-->
            <div class="a2-to">
                <span style="flex-shrink: 0;">支付备注：</span>
                <van-field v-model="upayForm.body" style="font-size: 0.4rem;line-height: normal;color: #666"/>
            </div>
        </div>
        <!-- 选择支付方式 -->
        <p class="a3-title">选择支付方式</p>
        <div class="uipay-a3">
            <van-radio-group v-model="wayCode">
                <van-cell-group>
                    <van-cell clickable @click="wayCode = 'WX_APP'" class="a3-cell">
                        <template #icon>
                            <img src="../../assets/wechat-icon.png" alt="wechat-icon.png" class="a3-icon"/>
                        </template>
                        <template #title>
                            <p class="a3-text">微信支付</p>
                        </template>
                        <template #right-icon>
                            <van-radio name="WX_APP"/>
                        </template>
                    </van-cell>
                    <van-cell clickable @click="wayCode = 'ALI_APP'" class="a3-cell">
                        <template #icon>
                            <img src="../../assets/alipay-icon.png" alt="alipay-icon.png" class="a3-icon"/>
                        </template>
                        <template #title>
                            <p class="a3-text">支付宝支付</p>
                        </template>
                        <template #right-icon>
                            <van-radio name="ALI_APP"/>
                        </template>
                    </van-cell>
                    <van-cell clickable @click="wayCode = 'YSF_JSAPI'" class="a3-cell">
                        <template #icon>
                            <img src="../../assets/yun-icon.png" alt="yun-icon.png" class="a3-icon"/>
                        </template>
                        <template #title>
                            <p class="a3-text">银联云闪付支付</p>
                        </template>
                        <template #right-icon>
                            <van-radio name="YSF_JSAPI"/>
                        </template>
                    </van-cell>
                    <van-cell clickable @click="wayCode = 'ONE_KEY_PAY'" class="a3-cell">
                        <template #icon>
                            <img src="../../assets/other-icon.png" alt="other-icon.png" class="a3-icon"/>
                        </template>
                        <template #title>
                            <p class="a3-text">一键支付</p>
                        </template>
                        <template #right-icon>
                            <van-radio name="ONE_KEY_PAY"/>
                        </template>
                    </van-cell>
                </van-cell-group>
            </van-radio-group>
        </div>
        <!-- 圆角弹窗 -->
        <van-popup v-model="showPopup" round position="bottom">
            <div class="uipay-a4">
                <p class="a4-title">分账方式</p>
                <van-radio-group v-model="divisionMode">
                    <van-cell-group style="border: 0px">
                        <van-cell clickable @click="divisionMode = 0" class="a4-cell">
                            <template #title>
                                <p class="a4-text">订单不分账</p>
                            </template>
                            <template #right-icon>
                                <van-radio :name="0"/>
                            </template>
                        </van-cell>
                        <van-cell clickable @click="divisionMode = 1" class="a4-cell">
                            <template #title>
                                <p class="a4-text">支付完成自动分账</p>
                            </template>
                            <template #right-icon>
                                <van-radio :name="1"/>
                            </template>
                        </van-cell>
                        <van-cell clickable @click="divisionMode = 2" class="a4-cell">
                            <template #title>
                                <p class="a4-text">手动分账</p>
                            </template>
                            <template #label>
                                <p class="a4-label">冻结商户资金，只能通过API发起分账后解冻</p>
                            </template>
                            <template #right-icon>
                                <van-radio :name="2"/>
                            </template>
                        </van-cell>
                    </van-cell-group>
                </van-radio-group>
                <van-button type="info" class="a4-btn" @click="upayForm.divisionMode = divisionMode; showPopup = false;">确认</van-button>
            </div>
        </van-popup>
        <div class="uipay-a5">
            <van-button type="info" class="a5-btn" @click="toPlay()">立即支付</van-button>
        </div>
    </div>
</template>

<script>
    import './uipay.css';
    import alipayIcon from '../../assets/alipay-icon.png';
    import wechatIcon from '../../assets/wechat-icon.png';
    import qqIcon from '../../assets/QQ.png'
    import yinglianIcon from '../../assets/YINGLIAN.png';
    import baiduIcon from '../../assets/BAIDU.png';
    import {formatMoney} from '../../utils/formatNum';
    import UUID from 'uuidjs';
    import {createQrCashierOrder} from '../../api/api';

    export default {
        name: "Uipay",
        data() {
            return {
                payList: [
                    {id: "alipay", name: "支付宝H5", icon: alipayIcon, action: true},
                    {id: "wetchat", name: "微信支付H5", icon: wechatIcon, action: false},
                    {id: "qq", name: "QQ钱包H5", icon: qqIcon, action: false},
                    {id: "quick", name: "快捷支付H5", icon: yinglianIcon, action: false},
                    {id: "unionPay", name: "银联H5", icon: yinglianIcon, action: false},
                    {id: "banking", name: "在线网银", icon: yinglianIcon, action: false},
                    {id: "baidu", name: "百度钱包H5", icon: baiduIcon, action: false}
                ],
                selectUipay: "alipay",
                isStartInput: false,
                upayForm: {
                    mchNo: "",
                    mchName: '',
                    appId: "",
                    mchOrderNo: "",
                    amount: '',
                    subject: "DEMO 订单测试",
                    body: "",
                    notifyUrl: "https://mch.uipay.cn/api/anon/paytestNotify/payOrder",
                    returnUrl: "",
                    divisionMode: 0
                },
                wayCode: "",
                divisionMode: 0,
                amountFormat: '',
                showPopup: false
            }
        },
        computed: {
            divisionModeText() {
                switch (this.upayForm.divisionMode) {
                    case 0:
                        return "订单不分账";
                    case 1:
                        return "支付完成自动分账";
                    case 2:
                        return "手动分账";
                }
                return '';
            }
        },
        created() {
            this.upayForm.mchNo = this.$route.query.mchNo;
            this.upayForm.appId = this.$route.query.appId;
            this.upayForm.mchName = this.$route.query.mchName;
            this.upayForm.mchOrderNo = `demo-${UUID.genV4().hexFields.node}`;
            // this.amountFormat = formatMoney(this.upayForm.amount);
            if (navigator.userAgent.includes("WeChat")) {
                this.wayCode = 'WX_APP';
            } else if (navigator.userAgent.includes("AlipayClient")) {
                this.wayCode = 'ALI_APP';
            } else if (navigator.userAgent.includes("CloudPay") || navigator.userAgent.includes("UnionPay")) {
                this.wayCode = 'YSF_JSAPI';
            } else {
                this.wayCode = 'ONE_KEY_PAY';
            }
        },


        mounted() {
            setTimeout(() => this.$refs['amount'].focus(), 100);
        },


        methods: {
            /**
             * 去支付
             */
            toPlay() {
                const params = {...this.upayForm, amount: Number(this.upayForm.amount) * 100};
                if (!params.body){params.body = params.mchName}
                if (this.wayCode == 'ONE_KEY_PAY') {
                    params['returnUrl'] = `https://pay.uipay.cn/#/uipay/${this.upayForm.appId}/${this.upayForm.mchNo}`;
                    params['wayCode'] = 'ONE_KEY_PAY';
                }
                createQrCashierOrder(params).then(result => {
                    document.location.href = result;
                }).catch(error => {
                    console.log(error);
                    alert(error.msg);
                });
                // const paramsArr = Object.keys(params).map(key => `${key}=` + params[`${key}`] );
                // document.location.href = "https://pay.uipay.cn/api/pay/qrCashierOrder?" + paramsArr.join('&');
            },
            /**
             * 点击编辑金额按钮触发
             */
            editAmount() {
                this.isStartInput = true;
                setTimeout(() => this.$refs['amount'].focus(), 100);
            },
            /**
             * 失去焦点的时候，同时也格式化输出
             */
            amountBlur() {
                this.isStartInput = false;
                this.upayForm.amount = Number(this.upayForm.amount)
                if (this.upayForm.amount) {
                    this.amountFormat = formatMoney(this.upayForm.amount);
                }
            }
        }
    }
</script>
